import template from '../templates/header.html';
import dropdown from './dropdown.js';
import dropdownItem from './dropdown-item.js';
import searchBar from './search-bar.js';
import {urlMap} from './common.js';
import {get} from './utils.js';
import to from 'await-to-js';
import { tree as cicaDataTree } from './cica-data/sidebar.js';
import { tree as industryResearchTree } from './industry-research/sidebar.js';
import { tree as newsTree } from './news/sidebar.js';

export default {
    name: 'myheader',
    template,
    props: ['nav'],
    mixins: [searchBar],
    components: {
        dropdown,
        dropdownItem
    },
    data() {
        return {
            user: null,
        };
    },
    computed: {
        menuVisibility() {
            const context = this;
            const routeNames = [
            ];
            return routeNames.includes(context.$route.name) ? 'hidden' : 'visible';
        },
        firstCicaDataItem() {
            return cicaDataTree.find(item => item.selectable !== false);
        },
        firstIndustryResearchItem() {
            return industryResearchTree.find(item => item.selectable !== false);
        },
        firstNewsItem() {
            return newsTree.find(item => item.selectable !== false);
        },
    },
    created() {
        getUser(this);
    },
    mounted() {
        const vm = this;
        setTimeout(() => {
            if (location.hash.slice(1) !== '/') {
                return;
            }
            // 默认打开第一个菜单
            vm.$router.replace({
                name: 'index',
                params: {
                    nav: vm.getfirstChild().termId
                }
            });
        }, 300);
    },
    methods: {
        getfirstChild(parent) {
            return this.nav.find(item => item.parent === parent) || {};
        },
        logout() {
            location.href = 'anonymous.html#/login';
        },
    }
};

async function getUser(context) {
    var [error, data] = await to(get(urlMap.user)); 
    if(!error) {
        context.user = data; 
        context.$set(context.$root.auth, 'user', data);
        var key = 'track-login';
        if(!localStorage.getItem(key)) {
            context.$root.track('login');
            localStorage.setItem(key, true);
        }
    }
}
