import template from '../templates/nav-item.html';

export default {
    name: 'nav-item',
    template,
    props: ['item', 'depth'],
    computed: {
        children() {
            var context = this;
            return context.$root.allNavItems.filter(one => one.parent === context.item.termId);
        }
    },
    methods: {
        getPath(item) {
            return {
                name: 'index', 
                params: {
                    nav: this.$route.params.nav,
                    menu: item.termId
                }
            };
        },
    }
};
