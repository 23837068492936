import template from '../templates/nav-item2.html';

export default {
    name: 'nav-item2',
    template,
    props: ['item', 'tree', 'depth'],
    computed: {
        children() {
            const context = this;
            return context.tree.filter(one => one.pid === context.item.id);
        }
    },
    methods: {
        getPath2(item) {
            if (item.selectable === false) {
                return '/empty';
            }
            const route = this.$route;
            return {
                name: item.routeName || route.name, 
                params: {
                    termId: item.termId,
                    contentId: item.contentId,
                }
            };
        },
    },
};
