import sidebar from '../custom-sidebar';
import navItem2 from '../nav-item2';
import { env } from '../common';

const treeMap = {
    qa: [
        {
            id: '1',
            name: '数据资源中心',
            pid: null,
            selectable: false,
        },
        {
            termId: '729450579511939072',
            name: '公司经营数据',
            routeName: 'cica-data-item',
            pid: '1',
        },
        {
            termId: '722775198880914432',
            name: '知识产权与标准制定',
            routeName: 'cica-data-item',
            pid: '1',
        },
        {
            id: '2',
            name: '数据报告',
            routeName: 'data-report',
            pid: null,
        },
    ],
};

const tempTree = treeMap[env] || [
    {
        id: '1',
        name: '数据资源中心',
        pid: null,
        selectable: false,
    },
    {
        termId: '929854979429357568',
        name: '公司经营数据',
        routeName: 'cica-data-item',
        pid: '1',
    },
    {
        termId: '929855195276636160',
        name: '知识产权与标准制定',
        routeName: 'cica-data-item',
        pid: '1',
    },
    {
        termId: '929855329435629568',
        name: '风电产品认证',
        routeName: 'cica-data-item',
        pid: '1',
    },
    {
        termId: '929855433068496896',
        name: '风电实验检测',
        routeName: 'cica-data-item',
        pid: '1',
    },
    {
        termId: '929855530925708288',
        name: '海上风电',
        routeName: 'cica-data-item',
        pid: '1',
    },
    {
        termId: '929855609011124224',
        name: '海外业务',
        routeName: 'cica-data-item',
        pid: '1',
    },
    {
        termId: '929855759267854336',
        name: '水务环保',
        routeName: 'cica-data-item',
        pid: '1',
    },
    {
        id: '2',
        name: '数据报告',
        routeName: 'data-report',
        pid: null,
    },
];

export const tree = tempTree.map(item => {
    return  {
        ...item,
        id: item.id || item.termId || item.contentId,
    };
});

export default {
    mixins: [sidebar],
    components: {
        navItem2,
    },
    name: 'data-item-sidebar',
    data() {
        return {
            tree,
        };
    },
};