import template from '../templates/sidebar.html';
import navItem from './nav-item.js';

export const autoExpandSidebarItem = {
    watch: {
        $route: {
            handler() {
                const context = this;
                context.$nextTick(() => {
                    const nav = context.$refs.nav;
                    if(!nav) {
                        return;
                    }
                    const link = nav.querySelector('.router-link-active');
                    if(!link) {
                        return;
                    }
                    let li = link.closest('.nav-item');
                    const openedItems = nav.querySelectorAll('.nav-item[open]');
                    openedItems.forEach(item => item.removeAttribute('open'));
                    for(; li; li = li.parentElement.closest('.nav-item')) {
                        li.setAttribute('open', 'open');
                    }
                });
            },
            immediate: true
        },
    },
};

export default {
    name: 'sidebar',
    template,
    mixins: [autoExpandSidebarItem],
    props: ['open'],
    components: {
        navItem,
    },
    computed: {
        tree() {
            var context = this;
            var nav = context.$route.params.nav;
            return context.$root.getNav(nav).filter(item => item.parent === nav);
        }
    },
    methods: {
        toggle(event) {
            var context = this;
            context.$root.openSidebar = false; // 移动版里隐藏侧边栏
            var link = event.target.closest('a');
            var li = link.closest('.nav-item');
            var samePage = context.$route.params.menu === li.dataset.menu;
            if(!samePage) {
                return;
            }
            var open = li.getAttribute('open');
            if(open) {
                li.removeAttribute('open');
            } else {
                li.setAttribute('open', 'open');
            }
        },
    }
};
