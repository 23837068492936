import template from '../templates/dropdown.html';

export default {
    name: 'dropdown',
    template,
    data() {
        return {
            open: false,
            showFn: null
        };
    },
    computed: {
        openup() {
            var context = this;
            if(!context.open) {
                return false;
            }
            var el = context.$el;
            var children = Array.from(el.children);
            var list = children.map(item => item.offsetHeight).sort((a, b) => b - a);
            var maxHeight = list[0];
            var bound = el.getBoundingClientRect();
            var bottomToScreen = window.innerHeight - bound.top - bound.height;
            return maxHeight > bottomToScreen;
        },
        opendown() {
            var context = this;
            if(!context.open) {
                return false;
            }
            return !context.openup; 
        }
    },
    watch: {
        open(checked) {
            var context = this;
            if(checked) {
                context.showFn = function(event) {
                    context.open = context.$el.contains(event.target); 
                };
                document.addEventListener('mouseup', context.showFn); 
            } else {
                document.removeEventListener('mouseup', context.showFn);
            }
        }
    },
    methods: {
        show(event) {
            if(event.isTrusted === false) {
                return;
            }
            var context = this;
            context.open = !context.open;
        }
    }
};

