import template from '../templates/custom-sidebar.html';
import { autoExpandSidebarItem } from './sidebar';

export default {
    template,
    mixins: [autoExpandSidebarItem],
    computed: {
        firstLevel() {
            return this.tree.filter(item => !item.pid);
        },
    },
    watch: {
        '$route.path': {
            handler() {
                this.close();
            },
        },
    },
    methods: {
        toggle(event) {
            var context = this;
            context.$root.openSidebar = false; // 移动版里隐藏侧边栏
            const link = event.target.closest('a');
            const li = link.closest('.nav-item');
            if (!li.getAttribute('selectable')) {
                event.preventDefault();
            }
            const open = li.getAttribute('open');
            if(open) {
                li.removeAttribute('open');
            } else {
                li.setAttribute('open', 'open');
            }
        },
        close() {
            this.$root.openSidebar = false;
        },
    },
};