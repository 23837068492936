import sidebar from '../custom-sidebar';
import navItem2 from '../nav-item2';
import { env } from '../common';

const treeMap = {
    qa: [
        {
            termId: '846057287259925504',
            name: '金风要闻',
            pid: null,
        },
        {
            termId: '846064800239858688',
            name: '金风案例',
            pid: null,
        },
        {
            termId: '846064913209297920',
            name: '金风产品',
            pid: null,
        },
        {
            termId: '849323386143418368',
            name: '金风荣誉',
            pid: null,
        },
        {
            termId: '849323569321282560',
            name: '金风之声',
            pid: null,
        },
        {
            termId: '849323706441522176',
            name: '金风报道',
            pid: null,
        },
    ],
};

const tempTree = treeMap[env] || [
    {
        termId: '937312530068595712',
        name: '金风要闻',
        pid: null,
    },
    {
        termId: '937312725636444160',
        name: '金风案例',
        pid: null,
    },
    {
        termId: '937312798847937536',
        name: '金风产品',
        pid: null,
    },
    {
        termId: '937312874697767936',
        name: '金风荣誉',
        pid: null,
    },
    {
        termId: '937312942414823424',
        name: '金风之声',
        pid: null,
    },
    {
        termId: '937313012241604608',
        name: '金风报道',
        pid: null,
    },
];

export const tree = tempTree.map(item => {
    return  {
        ...item,
        id: item.id || item.termId || item.contentId,
        routeName: 'news',
    };
});

export default {
    mixins: [sidebar],
    components: {
        navItem2,
    },
    name: 'news-sidebar',
    data() {
        return {
            tree,
        };
    },
};